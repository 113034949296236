<template>
    <div :class="'big-container '">
        <div :class="'container ' + primaryClass()"  :id="props.game +'-'+ props.index">&nbsp;</div>
        <ion-popover :trigger="props.game +'-'+ props.index" trigger-action="click" :dismiss-on-select="true">
            <ion-content>
                <ion-list>
                    <ion-item @click="$emit('update', props.game, props.index, 'Trophy')">
                        <ion-label>Trophy</ion-label>
                    </ion-item>
                    <ion-item @click="$emit('update', props.game, props.index, 'X')">
                        <ion-label>X</ion-label>
                    </ion-item>
                    <ion-item @click="$emit('update', props.game, props.index, null)">
                        <ion-label>Clear</ion-label>
                    </ion-item>
                </ion-list>
            </ion-content>
        </ion-popover>
    </div>
</template>

<script setup>
import { IonPopover, IonList, IonItem } from '@ionic/vue';
import { ref, watch } from 'vue'
const props = defineProps({
  game: String,
  index: Number,
  state: Array,
})

watch(props.state, (data, oldValue) => {
  if(oldValue === undefined) return
  if(data[props.index] === oldValue[props.index]) return

  const newValue = data[props.index]

  console.log("state changed", props.game, props.index, data[props.index], oldValue[props.index])
})

function primaryClass() {
    if(props.state.value[props.index] === 'Trophy') {
    return 'trophy'
  } else if(props.state.value[props.index] === 'X') {
    return 'redx'
  }
  return ''
}


</script>

<style scoped>
.big-container {
    margin:0px;
    padding:0px;
    /*background-color: rgba(255,0,0,0.5);*/
    padding: 5px;
}
    .container {
        width: 100%;
        height: 100%;
    }

    .trophy {
        background-image: url(https://cdn.discordapp.com/attachments/335996145060806676/1083660407744503818/Super_Mario_Kart_Trophy_Big.png);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .redx {
        background-image: url(https://cdn.discordapp.com/attachments/335996145060806676/1083661080657678376/800px-Red_x.svg.png);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }
</style>