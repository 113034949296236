<template>
  <ion-page class="page" v-if="state['smk']">
    <!-- <div :style="{ fontSize: postFontSize + 'em' }"></div> -->
    <results-box v-for="(v,i) in locations.smk" :key="'smk'+i" game="smk" :index="i" :state="results.smk" @update="updated" :style="locationToStyle(v)"></results-box>
    <results-box v-for="(v,i) in locations.mk64" :key="'mk64'+i" game="mk64" :index="i" :state="results.mk64" @update="updated" :style="locationToStyle(v)"></results-box>
    <results-box v-for="(v,i) in locations.mksc" :key="'mksc'+i" game="mksc" :index="i" :state="results.mksc" @update="updated" :style="locationToStyle(v)"></results-box>
    <results-box v-for="(v,i) in locations.mkdd" :key="'mkdd'+i" game="mkdd" :index="i" :state="results.mkdd" @update="updated" :style="locationToStyle(v)"></results-box>
    <results-box v-for="(v,i) in locations.mkds" :key="'mkds'+i" game="mkds" :index="i" :state="results.mkds" @update="updated" :style="locationToStyle(v)"></results-box>
    <results-box v-for="(v,i) in locations.mkwii" :key="'mkwii'+i" game="mkwii" :index="i" :state="results.mkwii" @update="updated" :style="locationToStyle(v)"></results-box>
    <results-box v-for="(v,i) in locations.mk7" :key="'mk7'+i" game="mk7" :index="i" :state="results.mk7" @update="updated" :style="locationToStyle(v)"></results-box>
    <results-box v-for="(v,i) in locations.mk8dx" :key="'mk8dx'+i" game="mk8dx" :index="i" :state="results.mk8dx" @update="updated" :style="locationToStyle(v)"></results-box>
  </ion-page>
</template>

<script setup>
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/vue';
import { useWebSocket } from '@vueuse/core'
import { ref, watch } from 'vue'

const state = ref({})
const results = {
  "smk": ref([]),
  "mk64": ref([]),
  "mksc": ref([]),
  "mkdd": ref([]),
    mkds: ref([]),
    mkwii: ref([]),
    mk7: ref([]),
    mk8dx: ref([]),
}

function locationToStyle(location) {
  return {
    left: location[0] + 'px',
    top: location[1] + 'px',
    width: location[2] + 'px',
    height: location[3] + 'px',
    position: 'absolute'
  }
}

const locations = {
  smk: [],
  mk64: [],
  mksc: [],
  mkdd: [],  
  mkds: [],
  mkwii: [],
  mk7: [],
  mk8dx: [],
}

function addLocation(game,x,y) {
  switch(game) {
    case "smk":
      locations.smk.push([x,y,140,110])
      break
    case "mk64":
      locations.mk64.push([x,y,140,110])
      break
    case "mksc":
      locations.mksc.push([x,y,100,76])
      break
    case "mkdd":
      locations.mkdd.push([x,y,100,75])
      break
    case "mkds":
      locations.mkds.push([x,y,100,75])
      break
    case "mkwii":
      locations.mkwii.push([x,y,100,75])
      break
    case "mk7":
      locations.mk7.push([x,y,100,75])
      break
    case "mk8dx":
      locations.mk8dx.push([x,y,100,75])
      break
  }
}

addLocation("smk", 40,190 )
addLocation("smk", 226, 190)
addLocation("smk", 40, 315)
addLocation("smk", 226, 315)

addLocation("mk64", 491, 190)
addLocation("mk64", 680, 190)
addLocation("mk64", 491, 315)
addLocation("mk64", 680, 315)

addLocation("mksc", 992, 185)
addLocation("mksc", 1127, 185)
addLocation("mksc", 992, 276)
addLocation("mksc", 1127, 276)
addLocation("mksc", 1062, 371)

addLocation("mkdd", 1504, 180)
addLocation("mkdd", 1638, 180)
addLocation("mkdd", 1504, 271)
addLocation("mkdd", 1638, 271)
addLocation("mkdd", 1573, 367)

addLocation("mkds", 105, 608)
addLocation("mkds", 235, 608)
addLocation("mkds", 105, 697)
addLocation("mkds", 235, 697)
addLocation("mkds", 105, 789)
addLocation("mkds", 235, 789)
addLocation("mkds", 105, 877)
addLocation("mkds", 235, 877)

addLocation("mkwii", 547, 608)
addLocation("mkwii", 679, 608)
addLocation("mkwii", 547, 697)
addLocation("mkwii", 679, 697)
addLocation("mkwii", 547, 789)
addLocation("mkwii", 679, 789)
addLocation("mkwii", 547, 877)
addLocation("mkwii", 679, 877)

addLocation("mk7", 992, 608)
addLocation("mk7", 1123, 608)
addLocation("mk7", 992, 697)
addLocation("mk7", 1123, 697)
addLocation("mk7", 992, 789)
addLocation("mk7", 1123, 789)
addLocation("mk7", 992, 877)
addLocation("mk7", 1123, 877)



addLocation("mk8dx", 1370, 607)
addLocation("mk8dx", 1500, 607)
addLocation("mk8dx", 1631, 607)
addLocation("mk8dx", 1760, 607)
addLocation("mk8dx", 1370, 696)
addLocation("mk8dx", 1500, 696)
addLocation("mk8dx", 1631, 696)
addLocation("mk8dx", 1760, 696)
addLocation("mk8dx", 1370, 787)
addLocation("mk8dx", 1500, 787)
addLocation("mk8dx", 1631, 787)
addLocation("mk8dx", 1760, 787)
addLocation("mk8dx", 1370, 877)
addLocation("mk8dx", 1500, 877)
addLocation("mk8dx", 1631, 877)
addLocation("mk8dx", 1760, 877)
addLocation("mk8dx", 1370, 965)
addLocation("mk8dx", 1500, 965)
addLocation("mk8dx", 1631, 965)
addLocation("mk8dx", 1760, 965)



const websocket = useWebSocket('wss://mktracker.pikachu.little-canada.org/ws', {
    autoReconnect: true,
    immediate: true
})

watch(websocket.data, (data) => {
  data = JSON.parse(data)
  state.value = data
  console.log("wss", data)

  for(const k in data) {
    if (data[k] !== undefined && results[k] !== undefined) {
      results[k].value = data[k].results
    }
  }
})

function updated(gameid, index, newval) {
  const data = {}
  data[gameid] = {
    index: index,
    value: newval
  }
  websocket.send(JSON.stringify(data))
}

</script>

<style>
  #smk0 {
    position: absolute;
    top: 50px;
    left: 200px;
  }

  .page {
    position:absolute !important;
    top:0 !important;
    bottom:1080px !important;
    left:0 !important;
    right:1920px !important;
    height:1080px;
    width:1920px;

    background-color: #f6f6f6;
    background-image: url(https://cdn.discordapp.com/attachments/335996145060806676/1122293450214228140/Trophy_Tracking_screen_Circuits.png)
  }

</style>
